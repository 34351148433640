import * as React from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Constants } from "../../config";
import {
  Alert,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";
import Moment from "react-moment";
import "moment-timezone";

export default function SOS() {
  const { enqueueSnackbar } = useSnackbar();
  const [initliazed, setInitialized] = useState(false);
  const [fetchSOS, { loading, error, data }] = useLazyQuery(
    Constants.api.queries.fetchSos,
    {
      fetchPolicy: "network-only",
      pollInterval: 15000,
      onError: (error) => {
        console.error("fetchSOS", error);
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );
  const [updateSOSStatus] = useMutation(
    Constants.api.mutations.updateSOSStatus,
    {
      update(cache, data) {
        fetchSOS();
      },
      onError: (error) => {
        console.error("login", error);
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );
  useEffect(() => {
    fetchSOS();
    setInitialized(true);
  }, [initliazed, fetchSOS]);
  console.debug("SOS->fetchSOS", loading, error, data);

  if (error) {
    return <Alert>Could not load data:{error.message}</Alert>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 800 }} aria-label="SOS table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Latitude</TableCell>
                  <TableCell>Longitude</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Show on map</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.allSos?.edges
                  .map((item: any) => item.node)
                  .map((sos: any) => (
                    <TableRow
                      key={sos.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{sos.id}</TableCell>  
                      <TableCell>{sos.latitude}</TableCell>
                      <TableCell>{sos.longitude}</TableCell>
                      <TableCell>{sos.phoneNumber}</TableCell>
                      <TableCell>
                        <Moment format="YYYY/MM/DD hh:mm:ss">
                          {sos.createdAt}
                        </Moment>
                      </TableCell>
                      <TableCell>
                      {/* https://www.google.com/maps/@${sos.latitude},${sos.longitude},16z */}
                      <Link href={`https://www.google.com/maps/search/?api=1&query=${sos.latitude},${sos.longitude}`}  target="_blank">Show on map</Link>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={sos.status}
                          onChange={(event) => {
                            updateSOSStatus({
                              variables: {
                                id: sos.id,
                                status: event.target.value,
                              },
                            });
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"NEW"}>NEW</MenuItem>
                          <MenuItem value={"IN_PROGRESS"}>IN_PROGRESS</MenuItem>
                          <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                        </Select>
                      </TableCell>
                      
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
