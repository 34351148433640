import {
  AppBar as MuiAppBar,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Drawer as MuiDrawer,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, NavigateFunction, useNavigate } from "react-router-dom";
import "./App.css";
import { Dashboard, News, NewsForm, SignIn, SkiLifts, SOS } from "./components";
import { Constants } from "./config";
import { RootState } from "./reducers/store";
import {
  Dashboard as DashboardIcon,
  Logout as LogoutIcon,
  Newspaper as NewspaperIcon,
  LocalHospital as LocalHospitalIcon,
} from "@mui/icons-material";


// function Copyright(props: any) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://tol.solutions/">
//         TOL Solutions
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const mdTheme = createTheme();
function App() {
  const dispatch = useDispatch();
  let navigate: NavigateFunction = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);
  if (!sessionStorage.getItem("token") && !authState.data) {
    return <SignIn />;
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <MuiAppBar>
        <Toolbar
        // sx={{
        //   pr: "24px", // keep right padding when drawer closed
        // }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Dashboard
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="logout"
            onClick={() => {
              sessionStorage.clear();
              dispatch({
                type: Constants.actions.auth.SET_AUTH_TOKEN,
                payload: { data: null },
              });
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
      <MuiDrawer variant="permanent" open={true} style={{ height: "100%" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Popova Shapka
          </Button>
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              navigate("/");
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/news");
            }}
          >
            <ListItemIcon>
              <NewspaperIcon />
            </ListItemIcon>
            <ListItemText primary="News" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate("/sos");
            }}
          >
            <ListItemIcon>
              <LocalHospitalIcon />
            </ListItemIcon>
            <ListItemText primary="SOS" />
          </ListItem>
        </List>
      </MuiDrawer>
      <Container style={{marginTop:'70px', marginLeft:'200px'}}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/skilifts" element={<SkiLifts />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsform" element={<NewsForm />} />
          <Route path="/sos" element={<SOS />} />
        </Routes>
        {/* <Copyright /> */}
      </Container>
    </ThemeProvider>
  );
}

export default App;
