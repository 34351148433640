import {
    Interfaces,
    Constants,
} from '../config';

const initialState: Interfaces.AuthState = {
    data:sessionStorage.getItem('token') || null,
    error:null,
    loading:false,
}

const beginLogin = (state: Interfaces.AuthState, action:Interfaces.GenericAction): Interfaces.SkiliftState =>{
    return {
        ...state,
        data:null,
        loading:true,
        error:null,
    }
}

const setAuthToken = (state: Interfaces.AuthState, action:Interfaces.GenericAction): Interfaces.SkiliftState =>{
    return {
        ...state,
        data:action.payload.data,
        loading:false,
        error:null,
    }
}

const loginFailed = (state: Interfaces.AuthState, action:Interfaces.GenericAction): Interfaces.SkiliftState =>{
    return {
        ...state,
        data:null,
        loading:false,
        error:action.payload.data,
    }
}

const handlers = {
    [Constants.actions.auth.BEGIN_LOGIN]: beginLogin,
    [Constants.actions.auth.SET_AUTH_TOKEN]: setAuthToken,
    [Constants.actions.auth.LOGIN_FAILED]: loginFailed,
}

function reducerFactory(initialState: Interfaces.AuthState, handlers: any) {
    return function (state = initialState, action: any) {
        const handler = handlers[action.type];
        if (handler) {
            console.debug(action.type, action);
            return handler(state, action);
        }
        return state;
    }
}
export default reducerFactory(initialState, handlers);