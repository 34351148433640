import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "../../config";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Delete as DeleteIcon } from "@mui/icons-material";

export default function News() {
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();

  const [initliazed, setInitialized] = useState(false);
  const [fetchPosts, { loading, error, data }] = useLazyQuery(
    Constants.api.queries.fetchPosts,
    { fetchPolicy: "network-only" }
  );
  const [deletePost] = useMutation(Constants.api.mutations.deletePost, {
    update(cache, data) {
      fetchPosts();
    },
    onError: (error) => {
      console.error("deletePost", error);
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
  useEffect(() => {
    fetchPosts();
    setInitialized(true);
  }, [initliazed, fetchPosts]);
  console.debug(loading, error, data);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            variant="contained"
            sx={{ maxWidth: "150px", alignSelf:'end'}} 
            onClick={() => {
              navigation("/newsform");
            }}
          >
            Add News
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 800 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell align="right">Url</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Actions</TableCell>
                  {/* <TableCell align="right">Status</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.allPosts.edges
                  .map((item: any) => item.node)
                  .map((post: any) => (
                    <TableRow
                      key={post.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{post.id}</TableCell>
                      <TableCell>{post.title}</TableCell>
                      <TableCell
                        align="right"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100px",
                        }}
                      >
                        {post.url}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100px",
                        }}
                      >
                        {post.data}
                      </TableCell>
                      <TableCell align="right">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="delete"
                          onClick={() => {
                            deletePost({
                              variables: {
                                id: post.id,
                              },
                            });
                          }}
                        >
                          <DeleteIcon />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
