import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "../../config";
export default function NewsForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();

//   const [initliazed, setInitialized] = useState(false);
  const [addPost, { error, loading}] = useMutation(Constants.api.mutations.createPost, {
    update(cache, data) {
        navigation('/news');
    },
    onError: (error) => {
      console.error("login", error);
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(!event.currentTarget.checkValidity()){
        event.currentTarget.reportValidity();
        return;
    }
    // event.target.validate();
    // eslint-disable-next-line no-console
    addPost({variables:{
        title: data.get('title'),
        subtitle: data.get('subtitle'),
        url: data.get('url'),
        data: data.get('data'),
    }});
    console.log({
        title: data.get('title'),
        subtitle: data.get('subtitle'),
        url: data.get('url'),
        data: data.get('data'),
    });
  };
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, display:"flex", flexDirection:"column"}}>
      <FormControl>
        <InputLabel htmlFor="title">Title</InputLabel>
        <Input 
            id="title" 
            aria-describedby="my-helper-text" 
            name="title"
            required
        />
        <FormHelperText id="my-helper-text">
          type post title here
        </FormHelperText>
      </FormControl>
      <br />
      <br />
      <FormControl>
        <InputLabel htmlFor="subtitle">Subtitle</InputLabel>
        <Input
          id="subtitle"
          aria-describedby="my-helper-text"
          name="subtitle"
        />
        <FormHelperText id="my-helper-text">
          type post subtitle here
        </FormHelperText>
      </FormControl>
      <br />
      <br />
      <FormControl>
        <InputLabel htmlFor="url">Url</InputLabel>
        <Input 
            id="url" 
            aria-describedby="my-helper-text" 
            name="url" 
            required/>
        <FormHelperText id="my-helper-text">
          paste link of image here
        </FormHelperText>
      </FormControl>
      <br />
      <br />
      <FormControl>
        <InputLabel htmlFor="data">Content</InputLabel>
        <Input
          id="data"
          aria-describedby="my-helper-text"
          name="data"
          multiline
          required
        />
        <FormHelperText id="my-helper-text">
          write contents of your post here
        </FormHelperText>
      </FormControl>
      <br />
      {error && <><br/><p>{error.message}</p></>}
      <FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth:"100px"}}
          disabled={loading}
        >
          {loading && <CircularProgress />} Save
        </Button>
      </FormControl>
    </Box>
  );
}
