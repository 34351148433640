import * as React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Switch,
} from "@mui/material";

import { Constants, Interfaces } from "../../config";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

function DashboardContent() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SkiLifts />
        </Paper>
      </Grid>
    </Grid>
  );
}

function SkiLifts() {
  const { enqueueSnackbar } = useSnackbar();

  const [initliazed, setInitialized] = useState(false);
  const [fetchSkiLifts, { loading, error, data }] = useLazyQuery(
    Constants.api.queries.fetchSkiLifts,
    { fetchPolicy: "network-only" }
  );
  const [updateStatus] = useMutation(
    Constants.api.mutations.updateSkiLiftStatus,
    {
      update(cache, data) {
        fetchSkiLifts();
      },
      onError: (error) => {
        console.error("login", error);
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );
  useEffect(() => {
    fetchSkiLifts();
    setInitialized(true);
  }, [initliazed, fetchSkiLifts]);
  console.debug(loading, error, data);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Working</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.allSkiLifts.edges
            .map((item: any) => item.node)
            .map((skilift: Interfaces.Skilift) => (
              <TableRow
                key={skilift.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {skilift.name}
                </TableCell>
                <TableCell align="right">{skilift.type}</TableCell>
                <TableCell align="right">{skilift.total}</TableCell>
                <TableCell align="right">{skilift.working}</TableCell>
                <TableCell align="right">
                  {skilift.status}
                  <Switch
                    checked={skilift.status}
                    onChange={(state) => {
                      updateStatus({
                        variables: {
                          id: skilift.id,
                          status: state.target.checked,
                        },
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
