import {
    ApolloClient,
    InMemoryCache,
    gql,
    DefaultOptions,
    createHttpLink,
} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";


import { Constants } from '.';

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

const apolloHttpLink = createHttpLink({
    uri: Constants.api.endpoints.graphQL,
})

const apolloAuthContext = setContext(async (_, {headers}) => {
    const jwt_token = sessionStorage.getItem('token')
    return {
        headers: {
            ...headers,
            Authorization: jwt_token ? `Bearer ${jwt_token}` : ''
        },
    }
})

// Setup your client
const restClient = new ApolloClient({
    cache:new InMemoryCache(),
    link: apolloAuthContext.concat(apolloHttpLink),
    // uri:Constants.api.endpoints.graphQL,
    // headers: ({
    //   authorization: sessionStorage.getItem('token')?`Bearer ${sessionStorage.getItem('token')}`:'',
    // }),
    defaultOptions:defaultOptions,      
});

export {
    restClient,
    gql,
};
