import { gql } from "@apollo/client";

const API_URL = 'https://shapka-api.tol.delivery';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    actions: {
        skilifts:{
            BEGIN_FETCH_SKI_LIFTS:'BEGIN_FETCH_SKI_LIFTS',
            SET_SKI_LIFTS:'SET_SKI_LIFTS',
            FETCH_SKI_LIFTS_FAILED:'FETCH_SKI_LIFTS_FAILED',
        },
        auth: {
          BEGIN_LOGIN: 'BEGIN_LOGIN',
          SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
          LOGIN_FAILED: 'LOGIN_FAILED',
        }
    },
    api:{
        endpoints:{
            graphQL: API_URL+'/graphql',
            auth: API_URL+'/auth',
        },
        queries: {
            fetchSkiLifts: gql`
            {
                allSkiLifts {
                  edges {
                    node {
                      id
                      name
                      type
                      status
                      typeDescription
                      yearOfConstruction
                      speed
                      transitTime
                      capacityPerHour
                      manufacturer
                      heightAndLengthInMeters
                      total
                      working
                      locationId
                      createdAt
                      updatedAt
                    }
                  }
                }
            }
            `,
            fetchPosts: gql`
            query posts {
              allPosts {
                edges {
                  node {
                    id
                    title
                    subtitle
                    url
                    data
                    userId
                    createdAt
                    updatedAt
                  }
                }
              }
            }
            `,
            fetchSos: gql`
            query sos {
              allSos {
                edges{
                  node{
                    id
                    latitude
                    longitude
                    altitude
                    phoneNumber
                    notes
                    status
                    createdAt
                    updatedAt
                  }
                }
              }
            }
            `,
        },
        mutations: {
          login: gql`
          mutation auth($username:String!, $password: String!){
            auth(username: $username, password: $password) {
              accessToken
              refreshToken
            }
          }          
          `,
          updateSkiLiftStatus: gql`
          mutation updateSkiLiftStatus($id:Int!, $status: Boolean!){
              updateSkiLiftStatus(input:{
                id:$id
                status:$status
              }) {
                ok
              }
            }
          `,
          createPost: gql`
          mutation createPost(
            $title:String!
            $data:String!
            $subtitle:String!
            $url:String!
          ) {
            createPost(input:{
              title:$title
              data:$data
              subtitle:$subtitle
              url:$url
              userId:1
            }){
              ok
              post {
                id
                title
                data
                subtitle
                url
                userId
              }
            }
          }`,
          deletePost: gql`
          mutation deletPost($id:Int!){
            deletePost(input:{
              id:$id
            }) {
              ok
            }
          }
          `,
          updateSOSStatus: gql`
          mutation updateSOSStatus($id: Int!, $status:SOSStatus!) {
            updateSOSStatus(input:{
              id:$id
              status:$status
            }) {
              ok
            }
          }
          `,
        }
    },
};